html {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#root {
  height: 100%;
  background-color: #fff;
}

